import React from "react"
import Layout from "../components/Layout"
import "../assets/css/praxis.css"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { getImage } from "gatsby-plugin-image"

const Tags = ({ data }) => {
  const {
    warteraum,
    anmeldung,
    ordination1,
    ordination2,
    beratungsraum,
    medzentrum,
    information,
  } = data
  return (
    <Layout>
      <div className="praxisImageContainer">
        <div className="praxisImage">
          <GatsbyImage
            image={getImage(medzentrum)}
            title={medzentrum.title}
            alt="MedZentrum Weiz"
            style={{ width: "100%" }}
          />
        </div>
        <div className="praxisTopContainer">
          <div className="praxisHeader">NeuroPraxis Weiz</div>

          <div className="praxisInfoWrapper">
            <div className="praxisInfoIcon">
              <GatsbyImage
                image={getImage(information)}
                title={information.title}
                alt="Information"
              />
            </div>
            <div className="praxisInfoText">
              Die Praxis ist behindertengerecht erreichbar. Sie liegt im 1.
              Stock (Lift) des MedZentrum Weiz.
            </div>
          </div>

          <div className="praxisInfoWrapper">
            <div className="praxisInfoIcon">
              <GatsbyImage
                image={getImage(information)}
                title={information.title}
                alt="Information"
              />
            </div>
            <div className="praxisInfoText">
              Die Praxis ist nach modernsten medizinischen Standards
              ausgestattet. Wir haben uns bemüht, die Ordination freundlich und
              ansprechend zu gestalten.
            </div>
          </div>

          <div className="praxisInfoWrapper">
            <div className="praxisInfoIcon">
              <GatsbyImage
                image={getImage(information)}
                title={information.title}
                alt="Information"
              />
            </div>
            <div className="praxisInfoText">
              Obwohl es das Bestreben des gesamten Teams ist, in unserer
              Termin-Praxis die Wartezeiten sehr kurz zu halten, kann es z.B.
              durch Notfälle zu wenn auch geringen Wartezeiten trotz Termin
              kommen - wir bitten hierfür um Verständnis.
            </div>
          </div>
        </div>
      </div>

      <div className="praxisHeader">Praxis Bilder</div>

      <div className="praxisGalleryWrapper">
        <div className="praxisGalleryContainerResponsive">
          <div className="praxisGalleryContainer">
            <GatsbyImage
              image={getImage(anmeldung)}
              title={anmeldung.title}
              alt="Anmeldung"
            />
            <div className="praxisGalleryText">
              <p>Anmeldung</p>
            </div>
          </div>
          <div className="praxisGalleryContainer">
            <GatsbyImage
              image={getImage(warteraum)}
              title={warteraum.title}
              alt="Warteraum"
            />
            <div className="praxisGalleryText">
              <p>Warteraum</p>
            </div>
          </div>
        </div>
        <div className="praxisGalleryContainerResponsive">
          <div className="praxisGalleryContainer">
            <GatsbyImage
              image={getImage(ordination1)}
              title={ordination1.title}
              alt="Ordination1"
            />
            <div className="praxisGalleryText">
              <p>Ordination 1</p>
            </div>
          </div>
          <div className="praxisGalleryContainer">
            <GatsbyImage
              image={getImage(ordination2)}
              title={ordination2.title}
              alt="Ordination2"
            />
            <div className="praxisGalleryText">
              <p>Ordination 2</p>
            </div>
          </div>
        </div>
        <div className="praxisGalleryContainer">
          <GatsbyImage
            image={getImage(beratungsraum)}
            title={beratungsraum.title}
            alt="Beratungsraum"
          />
          <div className="praxisGalleryText">
            <p>Beratungsraum</p>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Tags

export const pageQuery = graphql`
  query PraxisQuery {
    warteraum: contentfulAsset(
      contentful_id: { eq: "3fCpL2ujZBIMlALt2ib92I" }
    ) {
      gatsbyImageData(quality: 100)
      title
      description
    }
    anmeldung: contentfulAsset(
      contentful_id: { eq: "5vMNauCx1vnJFU0bCjfs3C" }
    ) {
      gatsbyImageData(quality: 100)
      title
      description
    }
    ordination1: contentfulAsset(
      contentful_id: { eq: "7CkngI8xe1uGTwh1RjHFoW" }
    ) {
      gatsbyImageData(quality: 100)
      title
      description
    }
    ordination2: contentfulAsset(
      contentful_id: { eq: "7kGNFwtTSxIp8C752ImNjq" }
    ) {
      gatsbyImageData(quality: 100)
      title
      description
    }
    beratungsraum: contentfulAsset(
      contentful_id: { eq: "3rHWDMMwuuiEvDL7KfR06Q" }
    ) {
      gatsbyImageData(quality: 100)
      title
      description
    }
    medzentrum: contentfulAsset(
      contentful_id: { eq: "75rT3owPiVVUXfDkKISJsR" }
    ) {
      gatsbyImageData(quality: 100)
      title
      description
    }
    information: contentfulAsset(
      contentful_id: { eq: "1aodisFRq5NhCZBAoCihDA" }
    ) {
      gatsbyImageData(quality: 100)
      title
      description
    }
  }
`
